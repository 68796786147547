<template>
  <div>
    <b-img :src="bgHeader" class="header mb-2" />
    <b-row class="m-0">
      <b-col lg="4" class="order-2 order-lg-1 my-0 p-0">
        <b-card>
          <b-card-body class="py-1 px-3 px-lg-2 d-flex flex-column justify-content-center">
            <div class="d-flex flex-column align-items-center justify-content-center">
              <b-icon-check-circle variant="primary" font-scale="4" class="mb-2 text-center" />
              <h3 class="text-center mb-1">
                {{ $t('onboarding.success.success') }}
              </h3>
              <p class="text-center mb-2 text-muted">
                {{ $t('onboarding.success.email-success') }}
              </p>
            </div>
            <!-- Detalles usuario -->
            <b-row >
              <b-col cols="12" sm="6" class="my-50">
                <h6>{{ $t('onboarding.success.custom-details') }}</h6>
                <div class="d-flex flex-column">
                  <small>{{ $t('onboarding.success.name') }}</small>
                  <small class="text-muted mb-50">{{ loggedUser.name }} {{ loggedUser.surname }} </small>
                  <small>{{ $t('onboarding.success.email') }}</small>
                  <small class="text-muted mb-50">{{ loggedUser.email }}</small>
                </div>
              </b-col>
            </b-row>

            <!-- Descargar factura -->
            <!--    <div class="d-flex align-items-center justify-content-center">
              <b-button variant="outline-primary">
                Descargar factura
              </b-button>
            </div> -->
          </b-card-body>
        </b-card>
      </b-col>
      <b-col lg="8" class="d-flex flex-column align-items-center justify-content-center order-1 order-lg-2">
        <h1 class="mt-1 mb-2 text-center">
          {{ $t('onboarding.success.welcome') }}
          <span class="text-primary" v-if="community">{{ translate(community.name) }}</span>
        </h1>
        <b-button variant="primary" class="ml-50 mb-2 mb-md-0" @click="ResumenUrl">
          {{ $t('onboarding.success.explore-button') }}
        </b-button>
        <!--     <span class="mt-2 text-muted">
          {{ $t('onboarding.success.redirect') }}
        </span> -->
        <div class="community-img">
          <b-img alt="join community image" :src="exploreCommunity" />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import UserAvatar from '@core/components/user/UserAvatar.vue';
import { getImageResource } from '@/@core/utils/image-utils';
import CustomfieldsModal from '@/@core/components/modal/CustomfieldsModal.vue';
import ClassifiersModal from '@/@core/components/modal/ClassifiersModal.vue';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import Placeholder from '@/assets/images/placeholders/light/placeholder-dark.jpeg';
import Vue from 'vue';
import ExploreCommunity from '@/assets/images/pages/community-explore.svg';
import BgHeader from '@/assets/images/placeholders/light/banner-nectios.png';

export default {
  name: 'SuccessTickets',
  components: {
    UserAvatar,
    ClassifiersModal,
    CustomfieldsModal,
    ExploreCommunity,
  },
  data() {
    return {
      isLoading: false,
      newComment: '',
      nextStep: false,
      disabled: false,
      disabledButtons: true,
      selectedAnswer: {},
      imageSrc: null,
      imageForum: null,
      forum: { name: '', description: '' },
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    bgHeader() {
      return BgHeader;
    },
    api() {
      return process.env.VUE_APP_API_PAYMENT;
    },
    token() {
      return Vue.$cookies.get('accessToken');
    },
    type() {
      return this.$route.params.type;
    },
    loggedUser() {
      return this.$store.getters.loggedUser;
    },
    item() {
      let result = {};
      if (this.$store.getters.subscriptions.data) {
        result = { ...result, ...this.$store.getters.subscriptions.data[0] };
      }
      if (this.$store.getters.subscriptionPlans?.unpaginated) {
        result = { ...result, ...this.$store.getters.subscriptionPlans.unpaginated[0] };
      }
      return result;
    },
    myData() {
      return this.$store.getters.purchase.unpaginated[this.$store.getters.purchase.unpaginated.length - 1];
    },
    Placeholder() {
      return Placeholder;
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    collective() {
      return this.$store.state.collective.collective;
    },
    community() {
      return this.$store.getters.currentCollective;
    },
    communityName() {
      return this.collective.name;
    },
    communitySlug() {
      return this.$route.params.community;
    },
    exploreCommunity() {
      return ExploreCommunity;
    },
  },

  // Fetch data when component is created!
  async created() {
    this.isLoading = true;
    this.isLoading = false;
    /*    setTimeout(async () => {
      await this.ResumenUrl();
    }, 3000); */
  },
  methods: {
    async ResumenUrl() {
      window.location = `${process.env.VUE_APP_COMMUNITY_TEMPLATE_URL.replace('{slug}', this.community.slug).replace(
        '{subdomain}',
        'app',
      )}/`;
    },
    translate(field) {
      return translateTranslationTable(this.currentLocale, field);
    },
  },
};
</script>
<style lang="scss" scoped>
.community-img {
  display: none;
}
.header {
  object-fit: cover;
  background-color: rgb(255, 255, 255);
  width: 100%;
  border-radius: 1.2rem;
}

@media (min-width: 767px) {
  .container {
    height: 55vh;
    max-height: 55vh;
  }
  .community-img {
    display: flex;
    max-width: 100%;
    img {
      width: 100%;
    }
  }
}
</style>
